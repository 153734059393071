import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
} from "react";
import Header from "commons/components/Header/Header";
import TextLoop from "react-text-loop";
import Confetti from "react-dom-confetti";
import { Button, Row } from "antd";
import "./LuckyRaw.less";
import services from "commons/apis/index";
import LuckyWinnerModal from "./LuckyWinnerModal";
import LuckyCustomerModal from "./LuckyCustomerModal";
import useGetCustomerWinner from "commons/hooks/useGetCustomerWinner";
import useGetListCustomerLuckyWheel from "commons/hooks/useGetListCustomerLuckyWheel";
import logo from "../../assets/images/logo.png";
import xoso from "../../assets/images/xoso.mp3";

const config = {
  angle: "180",
  spread: "240",
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: "10",
  width: "16px",
  height: "8px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};
export const LuckyRaw = () => {
  const [showTextAnimation] = useState(true);
  const [removeDrawnItem] = useState(false);
  const [results, setResults] = useState({});
  const [showResult, setShowResult] = useState(true);
  const [disableDrawButton, setDisableDrawButton] = useState(false);
  const [isModalLuckyWinner, setIsModalLuckyWinner] = useState(false);
  const [isModalLuckyCustomer, setIsModalLuckyCustomer] = useState(false);
  const [items] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ]);
  const [currentItems, setCurrentItems] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ]);
  const { data: dataCustomerWinner, loadMoreData } = useGetCustomerWinner();
  const { data: dataCustomer, loadMoreDataCustomer } =
    useGetListCustomerLuckyWheel();
  const [drawAgain, setDrawAgain] = useState(false);
  const [showMessageEndDraw, setShowMessageEndDraw] = useState(false);
  const [audio] = useState(new Audio(xoso));
  const elementItemOneRef = useRef();
  const elementItemTwoRef = useRef();
  const elementItemThreeRef = useRef();
  const elementItemFourRef = useRef();
  const elementItemFiveRef = useRef();
  const elementItemSixRef = useRef();
  const Playit = useCallback(() => {
    audio.pause();
  }, [audio])
  useEffect(() => { Playit() }, [Playit]);
  useEffect(() => {
    audio.addEventListener("ended", () => Playit());
    return () => {
      audio.removeEventListener("ended", () => Playit());
    };
  }, [Playit, audio]);
  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };
  const randomDrawItem = async () => {
    setShowResult(false);
    setDisableDrawButton(true);
    let maxItemIndex = currentItems.length;
    const randomIndex = Math.floor(Math.random() * maxItemIndex);
    elementItemOneRef.current.className = "number-item active";
    elementItemTwoRef.current.className = "number-item active";
    elementItemThreeRef.current.className = "number-item active";
    elementItemFourRef.current.className = "number-item active";
    elementItemFiveRef.current.className = "number-item active";
    elementItemSixRef.current.className = "number-item active";
    try {
      const dataETicker = await services.getETickets();
      const lastETicket = dataETicker[dataETicker.length - 1];
      if (!lastETicket) {
        const results = {
          numberOne: 0,
          numberTwo: 0,
          numberThree: 0,
          numberFour: 0,
          numberFive: 0,
          numberSix: 0,
          nameWinner: '',
        }
        setResults(results);
        setShowMessageEndDraw(true);
        return false;
      }
      const data = await services.getETicketCustomer(lastETicket?.id);
      if (!data) {
        return false;
      }
      const newStr = data?.bookingId.toString().padStart(6, '0') ?? "000000";
      sleep(showTextAnimation ? 5000 : 0).then(() => {
        const arrStr = newStr?.split("");
        const [numberOne, numberTwo, numberThree, numberFour, numberFive, numberSix] = arrStr;
        const results = {
          numberOne: Number(numberOne),
          numberTwo: Number(numberTwo),
          numberThree: Number(numberThree),
          numberFour: Number(numberFour),
          numberFive: Number(numberFive),
          numberSix: Number(numberSix),
          nameWinner: data?.fullName,
        };
        setResults(results);
        setShowResult(true);
        setDisableDrawButton(false);
        setDrawAgain(false);
      });
      if (removeDrawnItem) {
        const copyCurrentItems = [...currentItems];
        copyCurrentItems.splice(randomIndex, 1);
        setCurrentItems(copyCurrentItems);
      }
    } catch (error) {
      alert("Error: " + error);
    }
  };
  const onDrawAgain = () => {
    const results = {
      numberOne: 0,
      numberTwo: 0,
      numberThree: 0,
      numberFour: 0,
      numberFive: 0,
      numberSix: 0,
      nameWinner: '',
    }
    elementItemOneRef.current.className = "number-item";
    elementItemTwoRef.current.className = "number-item";
    elementItemThreeRef.current.className = "number-item";
    elementItemFourRef.current.className = "number-item";
    elementItemFiveRef.current.className = "number-item";
    elementItemSixRef.current.className = "number-item";
    setResults(results);
    setDrawAgain(true);
  };
  return (
    <div className="container-fix">
      <div className="body-container">
        <Header></Header>
        <div className="content-wrapper">
          <Row gutters={[16, 16]} style={{ height: "100%" }}>
            <div className="lucky-draw">
              <h1>Vòng quay may mắn</h1>
              <p className="result-fullname">{results?.nameWinner}</p>
              <div className="lucky-draw__block">
                <div className="number-item" ref={elementItemOneRef}>
                  <div className="cover-image">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="content">
                    {!showResult && items && (
                      <TextLoop
                        className="draw-text"
                        interval={100}
                        mask={true}
                        delay={100}
                        springConfig={{ stiffness: 180, damping: 8 }}
                        children={items}
                      />
                    )}
                    {showResult && (
                      <div className="draw-text-result">
                        {results?.numberOne ?? 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className="number-item" ref={elementItemTwoRef}>
                  <div className="cover-image">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="content">
                    {!showResult && items && <TextLoop
                      className="draw-text"
                      interval={130}
                      mask={true}
                      delay={200}
                      springConfig={{ stiffness: 180, damping: 8 }}
                      children={items}
                    />}
                    {showResult && <div className="draw-text-result">{results?.numberTwo ?? 0}</div>}
                  </div>
                </div>
                <div className="number-item" ref={elementItemThreeRef}>
                  <div className="cover-image">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="content">
                    {!showResult && items && (
                      <TextLoop
                        className="draw-text"
                        interval={150}
                        mask={true}
                        delay={300}
                        springConfig={{ stiffness: 180, damping: 8 }}
                        children={items}
                      />
                    )}
                    {showResult && (
                      <div className="draw-text-result">
                        {results?.numberThree ?? 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className="number-item" ref={elementItemFourRef}>
                  <div className="cover-image">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="content">
                    {!showResult && items && (
                      <TextLoop
                        className="draw-text"
                        interval={150}
                        mask={true}
                        delay={400}
                        springConfig={{ stiffness: 180, damping: 8 }}
                        children={items}
                      />
                    )}
                    {showResult && (
                      <div className="draw-text-result">
                        {results?.numberFour ?? 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className="number-item" ref={elementItemFiveRef}>
                  <div className="cover-image">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="content">
                    {!showResult && items && (
                      <TextLoop
                        className="draw-text"
                        interval={130}
                        mask={true}
                        delay={500}
                        springConfig={{ stiffness: 180, damping: 8 }}
                        children={items}
                      />
                    )}
                    {showResult && (
                      <div className="draw-text-result">
                        {results?.numberFive ?? 0}
                      </div>
                    )}
                  </div>
                </div>
                <div className="number-item" ref={elementItemSixRef}>
                  <div className="cover-image">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="content">
                    {!showResult && items && (
                      <TextLoop
                        className="draw-text"
                        interval={100}
                        mask={true}
                        delay={600}
                        springConfig={{ stiffness: 180, damping: 8 }}
                        children={items}
                      />
                    )}
                    {showResult && (
                      <div className="draw-text-result">
                        {results?.numberSix ?? 0}
                      </div>
                    )}
                  </div>
                </div>
                <Confetti active={showResult} config={config} />
              </div>
              {showMessageEndDraw && <p>Số lượt quay đã kết thúc!</p>}
              <div className="btn-action_draw">
                {drawAgain ? (
                  <Button
                    type="primary"
                    onClick={randomDrawItem}
                    disabled={disableDrawButton}
                    className="btn-draw"
                  >
                    {disableDrawButton ? "Đang quay..." : "Quay"}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={onDrawAgain}
                    disabled={disableDrawButton}
                    className="btn-draw__again"
                  >
                    Bắt đầu
                  </Button>
                )}
              </div>
            </div>
            <div className="footer-action">
              <Button
                onClick={async () => {
                  setIsModalLuckyCustomer(true);
                  await loadMoreDataCustomer();
                }}
                className="btn-submit"
              >
                Xem danh sách
              </Button>
              <Button
                onClick={async () => {
                  setIsModalLuckyWinner(true);
                  await loadMoreData();
                }}
                className="btn-submit"
              >
                Xem danh sách trúng thưởng
              </Button>
            </div>
          </Row>
        </div>
      </div>
      <LuckyWinnerModal
        visible={isModalLuckyWinner}
        onCancel={() => setIsModalLuckyWinner(false)}
        data={dataCustomerWinner}
      />
      <LuckyCustomerModal
        visible={isModalLuckyCustomer}
        onCancel={() => setIsModalLuckyCustomer(false)}
        data={dataCustomer}
      />
    </div>
  );
};
