import React, { Fragment, useEffect, useState } from "react";
import Header from 'commons/components/Header/Header';
import Iframe from 'react-iframe';
import cookie from 'js-cookie'
import "./LiveStream.less";
const LiveStream = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    const data = cookie.get("live-stream") && JSON.parse(cookie.get("live-stream"));
    setData(data);
  }, []);
  return (
    <Fragment>
      <div className="container-fix">
        <div className="body-container">
          <Header></Header>
          <div className="main dark-bg animate__animated animate__fadeIn" id="list-room-page">
            <Iframe
              url={data?.link ?? "https://www.youtube.com/embed/36YnV9STBqc"}
              position="relative"
              width="100%"
              id="myId"
              className="myClassname"
              height="100%"
              styles={{ height: "25px" }}
              allowfullscreen={"fullscreen"}
              allow="fullscreen"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LiveStream;